import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import styles from './Table.module.scss';
import { Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Select, InputGroup, SearchInput, Checkbox, DatePicker, MultipleSelect, DateRangePicker } from 'components/FormComponents';
import cn from 'classnames';
import { IconButton } from 'components/IconButton/IconButton';
import moment from 'moment';

const TableContext = createContext(null);

const useTableContext = () => {
  const context = useContext(TableContext)
  if (!context)
    throw new Error(`Table compound components cannot be rendered outside the Table component`)
  
  return context
} 

const Context = ({ 
  children, 
  onQueryUpdate = () => {}, 
  defaultQuery = {},
}) => {

  const [queryParams, setQueryParams] = useState(defaultQuery);
  const [sorting, setSorting] = useState([]);

  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [bulkUseAll, setBulkUseAll]   = useState(false);

  useEffect(() => {
    setSelectedAll(false);
    setSelectedIds([]);
    const filteredQueryParamsKeys = Object.keys(queryParams).filter(key => queryParams[key]?.value instanceof Array ? queryParams[key].value.length > 0 : !!queryParams[key]?.value);
    let params = {}
    filteredQueryParamsKeys.forEach(key => params[key] = queryParams[key].value)
    params.sorting = sorting.join(',');
    onQueryUpdate(params)
  }, [queryParams, sorting])

  useEffect(() => {
    if(!selectedAll) setSelectedIds([])
  }, [selectedAll])

  const updateQueryParams = (params) => setQueryParams(curr => ({ ...curr, ...params }))
  const clearQueryParams = () => {
    const { page, search } = queryParams;
    setQueryParams({ page, search });
  }

  const contextValue = { 
    queryParams, 
    updateQueryParams,
    clearQueryParams,
    selectedAll,
    setSelectedAll,
    selectedIds,
    setSelectedIds,
    bulkUseAll,
    setBulkUseAll,
    sorting,
    setSorting
  }

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  )
}

const TableContent = ({ className, children }) => <div className={cn(styles.root, { [className]: className })}>{children}</div>

export const Table = ({ children }) => (
  <table className={styles.table}>
    {children}
  </table>
)

const Placeholder = ({ children }) => <p className={styles.placeholder}>{children}</p>

const Head = ({ children }) => <thead className={styles.head}>{children}</thead>

const Header = ({ 
  children,
  align = 'left',
  bulkSelector = false,
  sortKey = false,
  noBorder = false
}) => {

  const { selectedAll, setSelectedAll, setSorting } = useTableContext();

  const [sortAsc, setSortAsc] = useState(true);

  const sort = () => {
    if(!sortKey) return;

    const newSortAsc = !sortAsc;
    setSortAsc(newSortAsc);
  
    setSorting(curr => {
      curr = curr.filter(item => item.split(':')[0] !== sortKey);
      return [`${sortKey}:${newSortAsc ? 'asc' : 'desc'}`, ...curr]
    })
  } 

  return (
    <th className={cn(styles.header, { [styles.headerNoBorder]: noBorder })} style={{ textAlign: align }}>
      {bulkSelector ? 
        <Checkbox checked={selectedAll} onClick={() => setSelectedAll(curr => !curr)} label={<span style={{ fontSize: '14px' }}>{children}</span>} /> :
        <span className={cn('d-flex', 'align-items-center', { ['u-pointer']: !!sortKey })} onClick={sort}>
          {children} 
          { sortKey && <FeatherIcon className='u-margin-left--small' icon={sortAsc ? 'chevron-down' : 'chevron-up'} size={15} /> }
        </span>
      }
    </th>
  )
}

const Body = ({ children }) => <tbody className={styles.body}>{children}</tbody>

const Row = ({ children }) => <tr className={styles.row}>{children}</tr>

const Col = ({ children, align = 'left', bulkSelector, selectorId, className }) => {

  const [checked, setChecked] = useState(false);

  const { selectedAll, selectedIds, setSelectedIds } = useTableContext();

  useEffect(() => {
    if(!bulkSelector) return;

    if(selectedAll) setSelectedIds(curr => curr.includes(selectorId) ? curr : [...curr, selectorId])
  }, [selectedAll]);

  useEffect(() => {
    if(!bulkSelector) return;

    setChecked(selectedIds.includes(selectorId))
  }, [selectedIds])

  const select = (value) => {
    if(value) setSelectedIds(curr => curr.includes(selectorId) ? curr : [...curr, selectorId]);
    else      setSelectedIds(curr => curr.filter(val => val !== selectorId));
  }

  return (
    <td className={cn(styles.column, { [className]: className })} style={{ textAlign: align }}>
      {bulkSelector ? 
        <Checkbox checked={checked} onClick={select} label={<span style={{ fontSize: '14px', fontWeight: '600' }}>{children}</span>} /> :
        children
      }
    </td>
  )
}

const Pagination = ({ page, records_per_page, total_records, total_pages }) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const pages = useMemo(() => {
    if(total_pages < 10) {
      return Array.from({length: total_pages}, (_, i) => i + 1)
    } else if(page <= 5) {
      return Array.from({ length: 10 }, (_, i) => i + 1); 
    } else if ((page + 6) > total_pages ) {
      return Array.from({ length: 10 }, (_, i) => i + (total_pages - 9))
    } else {
      return Array.from({ length: 10 }, (_, i) => i + (page - 5))
    }
  }, [page, total_pages])


  const firstRecordInPage = useMemo(() => {
    return (page - 1) * records_per_page + 1;
  }, [page, records_per_page])

  const lastRecordInPage = useMemo(() => {
    const lastRecord = page * records_per_page;
    return lastRecord > total_records ? total_records : lastRecord;
  }, [page, records_per_page, total_records])

  const setPage = (newPage) => {
    if(newPage === queryParams.page?.value) return;
    updateQueryParams({ page: { value: newPage }})
  }

  const firstPage = () => {
    if(page === 1) return;
    setPage(1);
  }

  const previousPage = () => {
    if(page === 1) return;
    setPage(page - 1);
  }

  const nextPage = () => {
    if(page === total_pages) return;
    setPage(page + 1);
  }

  const lastPage = () => {
    if(page === total_pages) return;
    setPage(total_pages);
  }
  
  const selectPage = (pageToGo) => {
    if(page === pageToGo) return;
    setPage(pageToGo);
  }

  return (
    <tfoot className={styles.footer}>
      <tr className={styles.pagination}>
        <td colSpan={4} className={styles.paginationDescription}>
          Showing: <span className='u-semibold'>{firstRecordInPage} - {lastRecordInPage} of {total_records} results</span>
        </td>
        <td className={styles.paginationLinks} colSpan="100%">
          <FeatherIcon 
            className={cn(styles.chevron, { [styles.chevronDisabled]: page === 1 })} 
            icon='chevrons-left'
            size={18}
            onClick={firstPage}
          />
          <FeatherIcon 
            className={cn(styles.chevron, 'u-margin-right--small', { [styles.chevronDisabled]: page === 1 })}
            icon='chevron-left' 
            size={18}
            onClick={previousPage}
          />
          {pages.map((index) => (
            <span key={index} onClick={() => selectPage(index)} className={cn(styles.paginationNumber, { [styles.paginationNumberSelected]: page === index })}>
              {index}
            </span>
          ))}
          <FeatherIcon 
            className={cn(styles.chevron, 'u-margin-left--small', { [styles.chevronDisabled]: page === total_pages })}
            icon='chevron-right'
            size={18}
            onClick={nextPage}
          />
          <FeatherIcon 
            className={cn(styles.chevron, { [styles.chevronDisabled]: page === total_pages })}
            icon='chevrons-right'
            size={18}
            onClick={lastPage}
          />
        </td>
      </tr>
    </tfoot>
  )
}

const FloatingFilters = ({ children, className }) => {

  const { queryParams, updateQueryParams, clearQueryParams } = useTableContext();

  const filters = useMemo(() => {
    const filterValues = [];
    const filterKeys = Object.keys(queryParams).filter(key => key !== 'page' && key !== 'search');

    filterKeys.forEach(key => {
      switch(queryParams[key]?.type) {
        case 'date_range':
          filterValues.push({ 
            title: queryParams[key].title,
            name: moment(queryParams[key].value[0]).format('MMM D, YYYY') + " - " + moment(queryParams[key].value[1]).format('MMM D, YYYY'), 
            queryParam: key, 
            type: queryParams[key].type 
          })
          break;
        case 'status':
          const value = queryParams[key].name;
          if(value instanceof Array) {
            value.forEach(subValue => {
              filterValues.push({ 
                title: queryParams[key].title,
                name: subValue, 
                queryParam: key, 
                type: queryParams[key].type
              })
            });
          } else {
            filterValues.push(value)
          }
          break;
      }
    })

    return filterValues;
  }, [queryParams])

  const removeFilter = (filter) => {
    const currentFilterValue = queryParams[filter.queryParam]?.name;

    if(currentFilterValue instanceof Array) {
      updateQueryParams({ [filter.queryParam]: { 
        name: currentFilterValue.filter(name => name !== filter.name), 
        value: queryParams[filter.queryParam]?.value,
        type: filter.type,
      }});
    } else {
      updateQueryParams({ [filter.queryParam]: null })
    }
  }

  return (
    <div className={cn(styles.floatingFilters, { [className]: className })}>
      <div className={styles.filterInputs}>
        <span>Filters</span> {children}
      </div>
      {filters.length > 0 && 
        <div className={styles.filterValues}>
          <IconButton icon='x' type='delete' small onClick={clearQueryParams} />
          {filters.map(filter => {
            return (
              <div key={filter.name} className={cn(styles.filterPill, 'u-semibold')}>
                <span className={styles.filterPillTitle}>
                  {filter.type === 'date_range' && <FeatherIcon icon='calendar' size={16} className={styles.filterPillIcon}/> }
                  {filter.title}
                </span>
                <span className={styles.filterPillValue}>
                  {filter.name}
                  <FeatherIcon icon='x' size={18} className={styles.filterPillDelete} onClick={() => removeFilter(filter)}/>
                </span>
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}
const FloatingFiltersLeft = ({ children }) => <div className={styles.left}>{children}</div>
const FloatingFiltersRight = ({ children }) => <div className={styles.right}>{children}</div>

const Filters = ({ children, className }) => <div className={cn(styles.filters, { [className]: className })}>{children}</div>
const LeftFilters = ({ children }) => <div className={styles.left}>{children}</div>
const RightFilters = ({ children }) => <div className={styles.right}>{children}</div>

const Search = ({ placeholder = 'Search...' }) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const [query, setQuery] = useState('');

  useEffect(() => {
    if(query === queryParams.search?.value) return;
    updateQueryParams({ search: { value: query }, page: { value: 1 }})
  }, [query])

  return (
    <SearchInput
      placeholder={placeholder}
      className={styles.search}
      onChange={setQuery}
    />
  )
}

const DateRangeFilter = ({ title, filterName }) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const [filterValue, setFilterValue] = useState(queryParams[filterName]?.value);

  // Update input from queryParams
  useEffect(() => {
    if(filterValue !== queryParams[filterName]?.value) {
      setFilterValue(queryParams[filterName]?.value);
    }
  }, [queryParams[filterName]])

  // Update queryParams from input
  useEffect(() => {
    if(!filterValue) return;

    if(queryParams[filterName]?.value === filterValue) return;

    updateQueryParams({ [filterName]: { title: title, name: title, value: filterValue, type: 'date_range' }, page: { value: 1 }})
  }, [filterValue])

  return (
    <div className={styles.filter}>
      <DateRangePicker value={filterValue} placeholder={title} onChange={setFilterValue} outputFormat='YYYY-MM-DD' />
    </div>
  )
}

const DateFilter = ({ title, filterName }) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const [filterValue, setFilterValue] = useState(queryParams[filterName]?.value);

  useEffect(() => {
    if(!filterValue) return;

    if(queryParams[filterName] === filterValue) return;

    updateQueryParams({ [filterName]: { value: filterValue }, page: { value: 1 }})
  }, [filterValue])

  return (
    <InputGroup title={title} className={cn(styles.filter, 'u-no-margin-bottom')}>
      <DatePicker onChange={setFilterValue} outputFormat='YYYY-MM-DD' />
    </InputGroup>
  )
}

const Filter = ({ title, filterName, options = [] }, useDefault=false) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const [filterValue, setFilterValue] = useState(queryParams[filterName]?.value);

  useEffect(() => {
    if(!filterValue) return;

    if(queryParams[filterName]?.value === filterValue) return;

    updateQueryParams({ [filterName]: { value: filterValue, name: options.find(o => o.value === filterValue)?.name }, page: { value: 1 }})
  }, [filterValue, options.length])

  return (
    <Select className={styles.filter} inputProps={{ onChange: (e) => setFilterValue(e.target.value) }} value={filterValue} useDefault={useDefault} placeholder={title}>
      {options.map(option => (
        <Select.Item key={option.value} value={option.value}>{option.name}</Select.Item>
      ))}
    </Select>
  )
}

const MultiSelectFilter = ({ 
  title, 
  filterName, 
  type,
  options  
}) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const [filterValue, setFilterValue] = useState(queryParams[filterName]?.value);

  // Update input from queryParams
  useEffect(() => {
    if(filterValue !== queryParams[filterName]?.value) {
      setFilterValue(queryParams[filterName]?.value);
    }

    if(queryParams[filterName]?.value.length !== queryParams[filterName]?.name.length) {
      const names = queryParams[filterName]?.name;
      const values = options.filter(o => names.includes(o.name)).map(o => o.value);
      setFilterValue(values);
    }

  }, [queryParams[filterName]])

  // Update queryParams from input
  useEffect(() => {
    if(!filterValue) return;

    if(queryParams[filterName] === filterValue) return;

    updateQueryParams({ 
      [filterName]: { 
        title: title,
        value: filterValue, 
        name: filterValue.map(value => options.find(o => o.value === value)?.name), 
        type 
      }, 
      page: { 
        value: 1 
      }
    })
  }, [filterValue, options?.length])

  return (
    <MultipleSelect className={styles.filter} value={filterValue} onChange={setFilterValue} placeholder={title} hideLabel>
      {options?.map(option => (
        <MultipleSelect.Item key={option.value} value={option.value} active={filterValue?.includes(option.value)}>{option.name}</MultipleSelect.Item>
      ))}
    </MultipleSelect>
  )
}

const BulkActions = ({ children }) => {
  const { selectedIds, bulkUseAll, setBulkUseAll } = useTableContext();

  return (
    <div className={cn(styles.bulkActions, { [styles.bulkActionsHidden]: selectedIds.length < 1 })}>
      <Badge className={styles.bulkActionsBadge} onClick={() => setBulkUseAll(!bulkUseAll)}>
        {bulkUseAll ? 'use all' : `${selectedIds.length} selected`}
      </Badge>
      <div className={styles.bulkActionsWrapper}>
        {children}
      </div>
    </div>
  )
}

const BulkAction = ({ icon, tip, onClick = () => {} }) => {
  const { selectedIds, bulkUseAll } = useTableContext();

  return (
    <OverlayTrigger placement='top' overlay={<Tooltip>{tip}</Tooltip>}>
      <div className={styles.bulkActionIconWrapper}>
        <FeatherIcon className={styles.bulkActionIcon} icon={icon} onClick={() => onClick(selectedIds, bulkUseAll)} />
      </div>
    </OverlayTrigger>
  )
}

Table.Context = Context;
Table.TableContent = TableContent;

Table.Head = Head;
Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.Col = Col;
Table.Pagination = Pagination;

Table.Filters = Filters;
Table.RightFilters = RightFilters;
Table.LeftFilters = LeftFilters;
Table.FloatingFilters = FloatingFilters;
Table.FloatingFiltersLeft = FloatingFiltersLeft;
Table.FloatingFiltersRight = FloatingFiltersRight;

Table.Search = Search;
Table.Filter = Filter;
Table.DateFilter = DateFilter;
Table.MultiSelectFilter = MultiSelectFilter;
Table.DateRangeFilter = DateRangeFilter;

Table.Placeholder = Placeholder;
Table.BulkActions = BulkActions;
Table.BulkAction = BulkAction;