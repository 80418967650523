import { useState } from 'react';
import { RefereeCard } from './components/RefereeCard/RefereeCard';
import { Form } from 'components/Form/Form/Form';
import { Referee } from './components/Referee/Referee';
import { CombinedReferees } from './components/CombinedReferees/CombinedReferees';
import Alert from 'components/Alert/Alert';
import { AddRefereeCard } from './components/AddRefereeCard/AddRefereeCard';
import { NewRefereeModal } from './components/NewRefereeModal/NewRefereeModal';
import { useApiUploadActions } from 'hooks/upload-actions';
import { useLazyGetFormsByIdQuery } from 'api/forms-api';
import cn from 'classnames';
import styles from './Reference.module.scss';

const COMBINED_ID = 0;

export const Reference = ({
  candidate,
  check,
  referees,
  company,
  brand,
  messagePopUp                 = () => {},
  addReferee                   = () => {},
  setLoading                   = () => {},
  updateReferee                = () => {},
  deleteReferee                = () => {},
  cancelReferee                = () => {},
  getPdfReport                 = () => {},
  getCombinedReport            = () => {},
  sendRequestEmail             = () => {},
  sendReplacementRequestEmail  = () => {},
}) => {

  const { getUpload, signUpload, createUpload, deleteUpload } = useApiUploadActions();

  const [getFormsById] = useLazyGetFormsByIdQuery();

  const [selectedReferee, setSelectedReferee] = useState();

  const [showRequestAlert, setShowRequestAlert] = useState(false);
  const [showRequestConfirmationAlert, setShowRequestConfirmationAlert] = useState(false);

  const [showNewRefereeModal, setShowNewRefereeModal] = useState(false);
  const [referenceForms, setReferenceForms] = useState([]);
  const [candidateForm, setCandidateForm] = useState();
  const [refereeFormId, setRefereeFormId] = useState();

  const renderCards = () => {
    let primaryCards = [];
    let backupCards = [];

    // Check if they all have the same form
    const firstFormId = referees[0]?.['form_id'];
    const allSameForm = referees.every(referee => referee['form_id'] === firstFormId);

    referees.forEach(referee => {
      let card = (
        <RefereeCard 
          key={referee.id}
          name={referee.name}
          referee={referee}
          selected={selectedReferee?.id === referee.id}
          disabled={referee.replaced_by}
          onClick={setSelectedReferee}
          tag={referee.replaced_by ? `Replaced by ${referees.find(r => r.id === referee.replaced_by)?.name}` : false}
        />
      )
      if(referee.backup) backupCards.push(card);
      else primaryCards.push(card);
    })
    return(
      <div className={styles.cards}>
        {allSameForm && referees.length > 1 && 
          <div>
            <RefereeCard
              combined
              title="COMBINED REPORT"
              name={referees.filter(referee => !referee.replaced_by && !referee.canceled).map(referee => referee.name).join(' & ')}
              referee={{ id: COMBINED_ID }}
              disabled={!check.completed_at}
              onClick={setSelectedReferee}
              selected={selectedReferee?.id === COMBINED_ID}
            />
            <div className={styles.divider}></div>
          </div>
        }

        <p>Primary Referees</p>
        { primaryCards }

        {referees.length < check?.details.referees && 
          <AddRefereeCard onClick={handleNewReferee} />
        }

        {backupCards.length > 0 &&
          <>
            <div className={styles.divider}/>
            <p>Backup Referees</p>
            {backupCards}
          </>
        }
      </div>
    )
  }

  const handleNewReferee = async() => {
    if(referenceForms.length < 1) {
      setLoading(true);

      const formIds = [];
      check.details.form_details.forEach(formDetails => {
        formIds.push(formDetails.candidate);
        formIds.push(formDetails.referee);
      })

      const forms = await getFormsById(formIds).unwrap();
      setReferenceForms(forms);
      setLoading(false);
    }
    setShowNewRefereeModal(true);
  }

  const handleNewRefereeFormSelection = (index) => {
    const referenceDetails = check.details.form_details[index];
    setRefereeFormId(referenceDetails.referee);
    const candidateForm = referenceForms.find(form => form.id === referenceDetails.candidate);
    setCandidateForm(candidateForm);
    setShowNewRefereeModal(false);
  }

  const onNewReferee = async(answers) => {
    setLoading(true);
    const data = {
      candidate_id: candidate.id,
      candidate_check_id: check.id,
      candidate_form_id: candidateForm.id,
      form_id: refereeFormId,
      answers
    }
    const referee = await addReferee(data);
    setSelectedReferee(referee);
    setCandidateForm(null);
    setShowRequestAlert(true);
    setLoading(false);
  }

  const onNewRequest = () => {
    setShowRequestConfirmationAlert(false);
    sendRequestEmail(selectedReferee.id);
  }

  return ( 
    <div className={styles.root}>
      <NewRefereeModal 
        visible={showNewRefereeModal} 
        forms={referenceForms} 
        formDetails={check.details.form_details}
        onSelect={handleNewRefereeFormSelection}
        onClose={() => setShowNewRefereeModal(false)}
      />

      <div className={styles.title}>Referees</div>
      <div className={styles.subtitle}>{referees?.length > 0 ? "View and update the list of referees provided by the candidate" : "No referees have been provided"}</div>
      <div className={styles.content}>
        { renderCards() }
        {candidateForm &&
          <div className={cn('card', 'card-with-border', 'u-min-width-medium', 'u-padding')}> 
            <h1 className='title-2 u-margin-bottom'>{candidateForm.name}</h1>
            <Form
              candidateId={candidate.id}
              country={company.country}
              textParams={{ candidate: candidate.first_name, job: candidate.job_role }}
              form={candidateForm}
              brand={brand}
              buttonTitle='Create'
              onSubmit={onNewReferee}
              getCandidateUpload={getUpload}
              deleteCandidateUpload={deleteUpload}
              signCandidateUpload={signUpload}
              createCandidateUpload={createUpload}
            />
          </div>
        }
        {(selectedReferee?.id === COMBINED_ID && !candidateForm) && 
          <CombinedReferees 
            candidate={candidate}
            check={check}
            referees={referees.filter(referee => !referee.replaced_by && !referee.canceled)} 
            setLoading={setLoading}
            messagePopUp={messagePopUp}
            getPdfReport={getCombinedReport}
            onClose={() => setSelectedReferee(null)}
          />
        }

        {(selectedReferee && selectedReferee.id !== COMBINED_ID && !candidateForm) && 
          <Referee 
            candidate={candidate}
            company={company}
            check={check}
            referee={selectedReferee} 
            setLoading={setLoading}
            messagePopUp={messagePopUp}
            updateReferee={updateReferee}
            deleteReferee={deleteReferee}
            cancelReferee={cancelReferee}
            getPdfReport={getPdfReport}
            setShowRequestAlert={setShowRequestAlert}
            onNewRequest={() => setShowRequestConfirmationAlert(true)}
            sendReplacementRequestEmail={sendReplacementRequestEmail}
            onClose={() => setSelectedReferee(null)}
          />
        }
        </div>
        <Alert  
          show={showRequestAlert}
          title="Would you like to send a new request to the referee?"
          ok="Send New Request" 
          cancel="No Thanks" 
          params={showRequestAlert}
          onOk={() => {
            setShowRequestAlert(false);
            sendRequestEmail(selectedReferee?.id);
          }}
          onCancel={() => { 
            setShowRequestAlert(false); 
          }}
        />
        <Alert  
          show={showRequestConfirmationAlert}
          title="Are you sure you want to send a new request?"
          ok="Send new request" 
          cancel="Cancel" 
          onOk={onNewRequest}
          onCancel={() => setShowRequestConfirmationAlert(false)}
        />
    </div>
  )
}