import React, { useEffect, useState } from 'react';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { useLazyGetUsersQuery, useDeleteUserMutation } from 'api/user-api';
import { loginAs } from 'api/authentication-slice';
import cookie from 'react-cookies';
import { Table } from 'components/Table/Table';
import { IconButton } from 'components/IconButton/IconButton';
import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import Alert from 'components/Alert/Alert';
import { useDocumentTitle } from 'hooks/document-title';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';

const AdminUsers = () => {

  useDocumentTitle('Users');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getUsers] = useLazyGetUsersQuery();
  const [deleteUser] = useDeleteUserMutation();

  const [loading, setLoading] = useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [actionUser, setActionUser] = useState();

  const [users, setUsers] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0
  })

  const fetchUsers = async(params = {}) => {
    setLoading(true);
    const { result } = await getUsers(params).unwrap();
    setPagination(result.pagination);
    setUsers(result.users);
    setLoading(false);
  }

  useEffect(() => { fetchUsers() }, [])

  const handleLoginAs = async(userId) => {
    setLoading(true);
    const { payload: result } = await dispatch(loginAs(userId));
    cookie.save('admin-token', cookie.load('token'), { path: '/' });
    cookie.save('admin-route', '/admin/users', { path: '/' });
    cookie.save('token', result.token, { path: '/' });
    setLoading(false);
    navigate('/dashboard');
  }

  const onDeleteUser = async() => {
    setDeleteConfirmation(false);
    try {
      setLoading(true);
      await deleteUser(actionUser.id);
      setUsers(curr => curr.filter(user => user.id !== actionUser.id));
      dispatch(messagePopUp({ text: 'User deleted.', state: MESSAGE_STATE_SUCCESS, hide: true }));
      setLoading(false);
    } catch(e) {
      dispatch(messagePopUp({ text: e.error.log, state: MESSAGE_STATE_ERROR, hide: true }));
      setLoading(false);
    }
  }

  const handleDeleteUser = (user) => {
    setActionUser(user);
    setDeleteConfirmation(true);
  }

  return (
    <AdminPage loading={loading} title='Users'>
      <Alert  
        show={deleteConfirmation}
        title={`Are you sure you want to delete ${actionUser?.first_name}`}
        message={`You are about to delete ${actionUser?.first_name} ${actionUser?.last_name} from ${actionUser?.company} company. This action is irreversible.`}
        ok="Delete" 
        onOk={onDeleteUser}
        cancel="Cancel" 
        onCancel={() => setDeleteConfirmation(false)}
      />
      
      <Table.Context onQueryUpdate={fetchUsers} defaultQuery={{ page: 1 }}>
        <Table.TableContent className='u-margin--large'>
          <Table.Filters>
            <Table.Search placeholder='Search for a user...'/>
          </Table.Filters>
          <Table className='u-margin--large'>
            <Table.Head>
              <Table.Row>
                <Table.Header>ID</Table.Header>
                <Table.Header sortKey="users.first_name">Name</Table.Header>
                <Table.Header>Email</Table.Header>
                <Table.Header>Company</Table.Header>
                <Table.Header sortKey="users.created_at">Created At</Table.Header>
                <Table.Header>Last Login</Table.Header>
                <Table.Header>Company Id</Table.Header>
                <Table.Header>Actions</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {users.map(user => (
                <Table.Row key={user.id}>
                  <Table.Col>{user.id}</Table.Col>
                  <Table.Col>{user.first_name} {user.last_name}</Table.Col>
                  <Table.Col>{user.email}</Table.Col>
                  <Table.Col>{user.company}</Table.Col>
                  <Table.Col><Moment format='DD/MM/YYYY'>{user.created_at}</Moment></Table.Col>
                  <Table.Col>{user.last_login && <Moment fromNow utc>{user.last_login}</Moment>}</Table.Col>
                  <Table.Col>{user.company_id}</Table.Col>
                  <Table.Col>
                    <IconButton small icon='key' tip='Login as' className='u-margin-right--small' onClick={() => handleLoginAs(user.id)} />
                    <IconButton small icon='trash-2' tip='Delete user' onClick={() => handleDeleteUser(user)} />
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Pagination {...pagination} />
          </Table>
        </Table.TableContent>
      </Table.Context>
    </AdminPage>
  )

}

export default AdminUsers
