import { useRef, useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "./DateRangePicker.module.scss"; 
import { useClickOutside } from "hooks/click-outside";
import FeatherIcon from "feather-icons-react";
import moment from "moment";

// TODO: Refactor to use as Main DatePicker
export const DateRangePicker = ({ 
  value,
  placeholder = 'Date Range', 
  className,
  format = 'dd-MM-yyyy',
  outputFormat = 'DD/MM/YYYY',
  useRange = true,
  onChange = () => {} 
}) => {
  const [date, setDate] = useState([null, null]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => setIsCalendarOpen(false));

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  // TODO: Set given value as date (when this component is not used as a filter)
  useEffect(() => {
    if(useRange) {
      if(value?.length !== 2) {
        setDate([null, null]);
        return;
      }

      let startDate = moment(value[0], outputFormat);
      let endDate = moment(value[1], outputFormat);

      if(!startDate.isValid() && !endDate.isValid()) {
        setDate([null, null]);
      } else {
        startDate = startDate.toDate();
        endDate = endDate.toDate();

        if(date[0]?.getTime() == startDate.getTime() && date[1]?.getTime() == endDate.getTime()) {
          return;
        }

        setDate([startDate, endDate]);
      }
    } else {
      const dateValue = moment(value, outputFormat);
      if(dateValue.isValid()) {
        setDate(dateValue.toDate());
      } else {
        setDate(null);
      }     
    }
  }, [value])

  // Send date value in output format
  useEffect(() => {
    if(!date) return;
    
    if(useRange) {
      let invalid = false;
      let formattedDates = [];

      for(let i = 0; i < date.length; i++) {
        const formattedDate = moment(date[i]).format(outputFormat);
        if(moment(formattedDate).isValid()){
          formattedDates.push(formattedDate);
        } else {
          invalid = true;
          break;
        }
      }

      if(!invalid) {
        onChange(formattedDates);
      }
    } else {
      const formattedDate = moment(date).format(outputFormat);

      if(moment(formattedDate).isValid()){
        onChange(formattedDate);
      }
    }
  }, [date])

  return (
    <div ref={wrapperRef} className={styles.root}>
      <button className={styles.button} onClick={toggleCalendar}><FeatherIcon icon="calendar" size={16} /> {placeholder}</button>
      
      {isCalendarOpen && (
        <div className="calendar-wrapper">
          <Calendar
            selectRange={useRange}
            onChange={setDate}
            value={date}
            minDetail="month"
            nextLabel={<FeatherIcon icon="chevron-right" size={15} />}
            next2Label={<FeatherIcon icon="chevrons-right" size={15} />}
            prevLabel={<FeatherIcon icon="chevron-left" size={15} />}
            prev2Label={<FeatherIcon icon="chevrons-left" size={15} />}
            formatShortWeekday={(locale, date) => moment(date).format('dd')}
          />
        </div>
      )}
    </div>
  );
};
