import { useMemo, useState } from 'react';
import AppPage from 'layouts/AppPage/AppPage';
import { useDocumentTitle } from 'hooks/document-title';
import { useLocale } from 'hooks/locale';
import { useSelector } from 'react-redux';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectCompany } from 'api/company-slice';
import { Tabs, Tab } from 'react-bootstrap';
import { ChecksReporting } from './tabs/ChecksReporting/ChecksReporting';
import { CandidatesReporting } from './tabs/CandidatesReporting/CandidatesReporting';
import { RefereesReporting } from './tabs/RefereesReporting/RefereesReporting';

const Reporting = () => {

  useDocumentTitle('Reporting');
  
  const companyChecks = useSelector(selectCompanyChecks);
  const company = useSelector(selectCompany);

  const locale = useLocale(company?.locale);
  
  const [loading, setLoading] = useState(false);

  const hasReference = useMemo(() => { 
    return !!companyChecks.find(cc => ['reference', 'custom_reference'].includes(cc.type));
  }, [companyChecks])

  return (
    <AppPage loading={loading}>
      <AppPage.Header title='Reporting' subtitle='Analyse your company data, draw insights and export reports.' />
      <Tabs defaultActiveKey='checks' className='tabs' mountOnEnter={true} unmountOnExit={true}>
        <Tab eventKey='checks' title="Checks">
          <ChecksReporting setLoading={setLoading} locale={locale} companyChecks={companyChecks} />
        </Tab>
        <Tab eventKey='candidates' title="Candidates">
          <CandidatesReporting setLoading={setLoading} locale={locale} />
        </Tab>
        {hasReference && 
          <Tab eventKey='referees' title="Referees">
            <RefereesReporting setLoading={setLoading} locale={locale} />
          </Tab>
        }
      </Tabs>
    </AppPage>
  )
}

export default Reporting