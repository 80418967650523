import { useGetPermissionsQuery } from "api/permissions-api";
import { useState, useEffect } from 'react';
import { List } from 'components/List/List';
import { RoleConfig } from "./components/RoleConfig/RoleConfig";

export const RolesTab = ({
  users,
  roles,
  updateRole      = () => {},
  deleteRole      = () => {},
  createRole      = () => {},
  setLoading      = () => {},
}) => {

  const { data: permissions, isLoading: loadingPermissions } = useGetPermissionsQuery();

  const [role, setRole] = useState();

  useEffect(() => { setLoading(loadingPermissions) }, [loadingPermissions])

  const onCreateRole = async() => {
    setLoading(true);
    const { payload: newRole } = await createRole({ name: 'New Role' });
    setRole(newRole);
    setLoading(false);
  }
  
  const onUpdateRole = async(id, data) => {
    setLoading(true);
    await updateRole(id, data);
    setLoading(false);
  }

  const onDeleteRole = async(id) => {
    setLoading(true);
    await deleteRole(id);
    setRole();
    setLoading(false);
  }

  return (
    <div className='d-flex'>
      <List 
        title='Roles'
        subtitle='Create roles and assign permissions.'
        className='u-margin-right--large'
        onNew={onCreateRole}
      >
        <List.Category title='Roles'>
          {roles.map(role => (
            <List.Item
              key={role.id}
              title={role.name}
              value={role}
              onClick={setRole}
            />
          ))}
        </List.Category>
      </List>
      {role && 
        <RoleConfig 
          role={role}
          users={users}
          permissions={permissions}
          onClose={() => setRole(null)}
          onDelete={onDeleteRole}
          onUpdate={onUpdateRole}
        />
      }
    </div>
  )

}