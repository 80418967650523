import axios from 'axios';
import cookie from 'react-cookies';

export function apiRequest(resource, params, method, authorization = false, file = false){
  let url = `${process.env.REACT_APP_API_URl}/${resource}`;
  if(method === 'get'){
    url += '?';
    let keys = Object.keys(params);
    for(let i=0; i < keys.length; i++){
      url += keys[i] + '=' + params[keys[i]] + "&";
    }
    params = {};
  }
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: params,
      responseType: file ? 'arraybuffer' : 'json',
      headers:  {
        'Accept': 'application/*, text/csv, image/*',
        'Content-Type': file ? 'application/pdf' : 'application/json',
        'Authorization': cookie.load('token')
      }
    }).then(response => {
      if(authorization && response.headers.authorization !== undefined){
        cookie.save('token', response.headers.authorization, { path: '/' })
      }
      if(file)
        resolve(response)
      else
        resolve(response.data);
    }).catch(error => {
      if(error.response)
        reject(error.response.data);
      else if(error.error)
        reject(error);
      else
        window.location.replace("/checkmate_error")
    });
  })
}

export const apiPartnerRequest = ({ 
  token, 
  resource, 
  params = {}, 
  method = "GET",
  file = false
}) => {
  let url = `${process.env.REACT_APP_API_URl}/partners/${resource}`;

  if(method === 'GET'){
    url += '?';
    let keys = Object.keys(params);
    for(let i=0; i < keys.length; i++){
      url += keys[i] + '=' + params[keys[i]] + "&";
    }
    params = {};
  }

  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: params,
      responseType: file ? 'arraybuffer' : 'json',
      headers:  {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(
      response => file ? resolve(response) : resolve(response.data.result),
      error => reject(error.response.data)
    );
  })
}

export default apiRequest;

export const toQueryParams = (params = {}) => {
  return Object.keys(params).map(key => params[key] instanceof Array ?  `${key}=${params[key].join(',')}` : `${key}=${encodeURIComponent(params[key])}`).join('&');
}