
import styles from './ReportingWidget.module.scss';
import { Badge } from 'react-bootstrap';
import cn from 'classnames';

const Types = {
  Primary: 'primary',
  Candidate: 'candidate',
  InProgress: 'in_progress',
  Overdue: 'overdue',
  Completed: 'completed',
  Canceled: 'canceled',
  Warning: 'warning'
}

export const ReportingWidget = ({ children }) => (
  <div className={cn(styles.root, 'card', 'card-with-border', 'u-padding', 'u-margin-bottom--large')}>
    {children}
  </div>
)

const Tile = ({ type = 'primary', disabled = false, children }) => {
  return (
    <div className={cn(styles.tile, styles[`tile--${type}`], 'card', 'card-with-border', 'background-secondary', { [styles.disabled]: disabled })}>
      <div className={cn('card_content', styles.wrapper)}>
        {children}
      </div>
    </div>
  )
}

const Divider = () => <div className={styles.divider} />

const WidgetBadge = ({ children }) => (
  <Badge className={styles.badge}>
    {children}
  </Badge>
)

const Value = ({ children }) => <h1 className={styles.value}>{children}</h1>
const Label = ({ children }) => <p className={styles.subtitle}>{children}</p>


ReportingWidget.Tile = Tile;
ReportingWidget.Badge = WidgetBadge;
ReportingWidget.Value = Value;
ReportingWidget.Label = Label;
ReportingWidget.Divider = Divider;
ReportingWidget.Types = Types;