import { useState } from 'react';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetCheckTypesQuery } from 'api/check-types-api';
import { useGetAllCompaniesListQuery } from 'api/company-api';
import { useLazyGetAllCandidateChecksQuery } from 'api/candidate-checks-api';
import { Table } from 'components/Table/Table';
import Moment from 'react-moment';
import { useEffect } from 'react';
import { useDocumentTitle } from 'hooks/document-title';

const AdminCandidateChecks = () => {
  const [loading, setLoading] = useState(true);
  const [candidateChecks, setCandidateChecks] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const [getAllCandidateChecks] = useLazyGetAllCandidateChecksQuery();
  const { data: companiesData } = useGetAllCompaniesListQuery();
  const { data: checkTypes } = useGetCheckTypesQuery();

  const [checkType, setCheckType] = useState();

  useDocumentTitle('Candidate Checks')

  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0
  });

  useEffect(() => {
    if(!companiesData) return;

    setCompaniesOptions([{ name: 'All', value: 'all' }, ...companiesData.result.map(c => ({ name: c.name, value: c.id }))]);
  }, [companiesData])

  const fetchCandidateChecks = async(params = {}) => {
    setLoading(true);
    const { result } = await getAllCandidateChecks(params).unwrap();
    setPagination(result.pagination);
    setCandidateChecks(result.candidate_checks);
    if(params.check_type && params.check_type !== 'all') setCheckType(checkTypes.find(checkType => checkType.slug === params.check_type));
    setLoading(false);
  }
  
  return (
    <AdminPage loading={loading} title='Checks'>
      {checkTypes?.length > 0 &&
        <Table.Context onQueryUpdate={fetchCandidateChecks} defaultQuery={{ page: 1, status: 'all', search: '', company: 'all' }}>
          <Table.TableContent className='u-margin--large'>
            <Table.Filters>
              <Table.LeftFilters>
                <Table.Search placeholder='Search for a candidate...' />
              </Table.LeftFilters>
              <Table.RightFilters>
                <Table.Filter title='Type' filterName='check_type' options={[{ name: 'All', value: 'all' }, ...checkTypes?.map(checkType => ({ name: checkType.title, value: checkType.slug }))]}/>
                <Table.Filter title='Company' filterName='company' options={companiesOptions}/>
                <Table.Filter title='Status' filterName='status' options={[
                  { name: 'All', value: 'all' },
                  { name: 'In-Progress', value: 'in-progress' },
                  { name: 'Completed', value: 'completed' },
                  { name: 'Overdue', value: 'overdue' },
                  { name: 'Canceled', value: 'canceled' }
                ]}/>
              </Table.RightFilters>
            </Table.Filters>
            <Table className='u-margin--large'>
              <Table.Head>
                <Table.Row>
                  <Table.Header>ID</Table.Header>
                  <Table.Header>Status</Table.Header>
                  <Table.Header>Name</Table.Header>
                  <Table.Header>Email</Table.Header>
                  <Table.Header>Created at</Table.Header>
                  <Table.Header>Submitted at</Table.Header>
                  <Table.Header>Completed at</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {candidateChecks.map(candidateCheck => {
                  const currentCheckType = checkType || checkTypes.find(checkType => checkType.slug === candidateCheck.type)
                  return (
                    <Table.Row key={candidateCheck.id}>
                      <Table.Col><Link to={`/admin/candidates/${candidateCheck.candidate_id}`} className='u-link dark'>{candidateCheck.candidate_id}</Link></Table.Col>
                      <Table.Col>
                        <OverlayTrigger overlay={<Tooltip>{currentCheckType.title}</Tooltip>}>
                          <span className={`u-txt-status-${candidateCheck.status}`}>
                            <FontAwesomeIcon icon={currentCheckType.icon} />
                          </span>
                        </OverlayTrigger>
                      </Table.Col>
                      <Table.Col>{`${candidateCheck.first_name} ${candidateCheck.last_name}`}</Table.Col>
                      <Table.Col>{candidateCheck.email}</Table.Col>
                      <Table.Col><Moment format='DD/MM/YYYY'>{candidateCheck.created_at}</Moment></Table.Col>
                      <Table.Col>{candidateCheck.submitted_at ? <Moment format='DD/MM/YYYY'>{candidateCheck.submitted_at}</Moment> : '-'}</Table.Col>
                      <Table.Col>{candidateCheck.completed_at ? <Moment format='DD/MM/YYYY'>{candidateCheck.completed_at}</Moment> : '-'}</Table.Col>
                    </Table.Row>
                  )
                })}
              </Table.Body>
              <Table.Pagination {...pagination} />
            </Table>
          </Table.TableContent>
        </Table.Context>
      }
    </AdminPage>
  )
}

export default AdminCandidateChecks;