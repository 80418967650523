import { useState } from 'react';
import styles from './AdminCandidates.module.scss';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetCheckTypesQuery } from 'api/check-types-api';
import { useGetAllCompaniesListQuery } from 'api/company-api';
import { useLazyGetAllCandidatesQuery } from 'api/candidates-api';
import { Table } from 'components/Table/Table';
import { PartnersHuman } from 'constants/partners';
import Moment from 'react-moment';
import cn from 'classnames';
import { useEffect } from 'react';
import { useDocumentTitle } from 'hooks/document-title';

const AdminCandidates = () => {
  const [loading, setLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const [getAllCandidates] = useLazyGetAllCandidatesQuery();
  const { data: companiesData } = useGetAllCompaniesListQuery();
  const { data: checkTypes } = useGetCheckTypesQuery();

  useDocumentTitle('Candidates')

  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0
  });

  useEffect(() => {
    if(!companiesData) return;

    setCompaniesOptions([{ name: 'All', value: 'all' }, ...companiesData.result.map(c => ({ name: c.name, value: c.id }))]);
  }, [companiesData])

  const fetchCandidates = async(params = {}) => {
    setLoading(true);
    const { result } = await getAllCandidates(params).unwrap();
    setPagination(result.pagination);
    setCandidates(result.candidates);
    setLoading(false);
  }
  
  return (
    <AdminPage loading={loading} title='Candidates'>
      {checkTypes?.length > 0 &&
        <Table.Context onQueryUpdate={fetchCandidates} defaultQuery={{ page: 1, status: 'all', search: '', company: 'all' }}>
          <Table.TableContent className='u-margin--large'>
            <Table.Filters>
              <Table.LeftFilters>
                <Table.Search placeholder='Search for a candidate...' />
              </Table.LeftFilters>
              <Table.RightFilters>
                <Table.Filter title='Company' filterName='company' options={companiesOptions}/>
                <Table.Filter title='Status' filterName='status' options={[
                  { name: 'All', value: 'all' },
                  { name: 'Waiting for Submission', value: 'waiting_for_submission' },
                  { name: 'Waiting for Completion', value: 'waiting_for_completion' },
                  { name: 'In-Progress', value: 'in-progress' },
                  { name: 'Completed', value: 'completed' },
                  { name: 'Deleted', value: 'deleted' },
                ]}/>
              </Table.RightFilters>
            </Table.Filters>
            <Table className='u-margin--large'>
              <Table.Head>
                <Table.Row>
                  <Table.Header>ID</Table.Header>
                  <Table.Header>Name</Table.Header>
                  <Table.Header>Email</Table.Header>
                  <Table.Header>Created at</Table.Header>
                  <Table.Header>Checks</Table.Header>
                  <Table.Header>Partner</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {candidates.map(candidate => (
                  <Table.Row key={candidate.id}>
                    <Table.Col><Link to={`/admin/candidates/${candidate.id}`} className='u-link dark'>{candidate.id}</Link></Table.Col>
                    <Table.Col>{`${candidate.first_name} ${candidate.last_name}`}</Table.Col>
                    <Table.Col>{candidate.email}</Table.Col>
                    <Table.Col><Moment format='DD/MM/YYYY'>{candidate.created_at}</Moment></Table.Col>
                    <Table.Col>
                      <div className={styles.checks}>
                        {candidate.checks && 
                          JSON.parse(candidate.checks).map(check => {
                            const checkType = checkTypes?.find(checkType => checkType.slug === check.type);
                            if(!checkType) return;
                            return (
                              <OverlayTrigger key={check.id} overlay={<Tooltip>{checkType.title}</Tooltip>}>
                                <div className={cn(styles.check, `u-txt-status-${check.status}`)}>
                                  <FontAwesomeIcon icon={checkType.icon} />
                                </div>
                              </OverlayTrigger>
                            )
                          })
                        }
                      </div>
                    </Table.Col>
                    <Table.Col>{PartnersHuman[candidate.partner]}</Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Pagination {...pagination} />
            </Table>
          </Table.TableContent>
        </Table.Context>
      }
    </AdminPage>
  )
}

export default AdminCandidates;