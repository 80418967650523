import InputValidator from 'helpers/input-validator';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import VerificationCard from '../VerificationCard/VerificationCard';
import FeatherIcon from 'feather-icons-react';
import styles from './ReportStaticHeader.module.scss';
import { useEffect, useState } from 'react';

export const ReportStaticHeader = ({
  referee,
  candidate,
  company
}) => {

  const [companyMailValidator, setCompanyMailValidator] = useState(); 
  const [linkedinValidator, setLinkedinValidator] = useState();
  const [ipValidator, setIpValidator] = useState();

  useEffect(() => {
    if(!referee) return; 

    if(referee.email === candidate.email) {
      setCompanyMailValidator(<div className="verification error"><FeatherIcon icon="alert-circle"/> Email addresses match</div>);
    } else if (InputValidator.CompanyEmailValidator(referee.email)) {
      setCompanyMailValidator(<div className="verification success"><FeatherIcon icon="check-circle"/> Registered Company Email</div>);
    } else {
      setCompanyMailValidator(<div className="verification warn"><FeatherIcon icon="alert-circle"/> Non company mail</div>);
    }

    if(referee.linkedin_profile) {
      setLinkedinValidator(<div className="verification success"><FeatherIcon icon="check-circle"/> Verified Linkedin</div>);
    } else {
      setLinkedinValidator(<div className="verification warn"><FeatherIcon icon="alert-circle"/> No information provided</div>);
    }

    if(referee.ip !== candidate.ip) {
      setIpValidator(<div className="verification success"><FeatherIcon icon="check-circle"/> Unique IP Address</div>);
    } else {
      setIpValidator(<div className="verification error"><FeatherIcon icon="alert-circle"/> IP address matches candidate</div>);
    }
  }, [referee])

  return(
    <div className={styles.root}>
      <div className={styles.banner}>
        <div>{company}</div>
        <div className={styles.logo}>Powered by <img src="assets/images/checkmate/icon-label.svg" alt="checkmate"/></div>
      </div>
      <div className={styles.candidate}>
        <div className={styles.name}>
          <div className={styles.label}>Reference check for</div>
          <div className={styles.value}>{candidate.name}</div>
        </div>
        <div className={styles.infoField}>
          <p className={styles.title}>role</p>
          <p className={styles.value}>{candidate.job_role}</p>
        </div>
        <div className={styles.infoField}>
          <p className={styles.title}>team</p>
          <p className={styles.value}>{candidate.team_name}</p>
        </div>
        <div className={styles.infoField}>
          <p className={styles.title}>create on</p>

          <p className={styles.value}><Moment format="DD/MM/YYYY">{referee.created_at}</Moment></p>
        </div>
        <div className={styles.infoField}>
          <p className={styles.title}>completed on</p>
          <p className={styles.value}><Moment format="DD/MM/YYYY">{referee.completed_at}</Moment></p>
        </div>
      </div>
      <div className={styles.referee}>
        <div className={styles.name}>
          <div className={styles.label}>Referee name</div>
          <div className={styles.value}>{referee.name}</div>
        </div>
        <div className={styles.infoField}>
          <p className={styles.title}>role</p>
          <p className={styles.value}>{referee.role}</p>
        </div>
        <div className={styles.infoField}>
          <p className={styles.title}>company</p>
          <p className={styles.value}>{referee.company_name}</p>
        </div>
        {referee.answered_on_behalf_user &&
          <div className={styles.infoField}>
            <p className={styles.title}>On behalf of by</p>
            <p className={styles.value}>{referee.answered_on_behalf_user}</p>
          </div>
        }
      </div>
      <div className={styles.verificationCards}>
        <VerificationCard>
          <div className='title'><FeatherIcon icon="mail"/> email address</div>
          <a href={"mailto:"+referee.email}>{referee.email}</a>
          {companyMailValidator}
        </VerificationCard>
        <VerificationCard>
          <div className='title'><FontAwesomeIcon icon={faLinkedin} size="2x"/> linkedin profile</div>
          <a href={`mailto:${referee.linkedin_profile}`}>{referee.linkedin_profile ? referee.linkedin_profile : '-'}</a>
          {linkedinValidator}
        </VerificationCard>
        <VerificationCard>
          <div className='title'><FeatherIcon icon="shield"/> ip address</div>
          <div className="static-value">{referee.ip}</div>
          {ipValidator}
        </VerificationCard>
      </div>
    </div>
  )
  
}
