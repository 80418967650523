import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import styles from './RefereeCard.module.scss';
import cn from 'classnames';

export const RefereeCard = ({
  title='REFEREE NAME',
  name,
  referee,
  selected,
  disabled,
  tag,
  combined=false,
  onClick = () => {}
}) => ( 
  <div 
    className={cn(styles.root, { 
        [styles.rootSelected]: selected,
        [styles.rootDisabled]: disabled,
      }
    )} 
    onClick={() => onClick(referee)}
  >
    <div className={styles.details}>
      <div className={styles.title}>{title}</div>
      <div className={styles.name}>{name}</div>

      {!combined && 
        <Badge className={cn(styles.status, `u-status-${referee.status}`, { [styles.tag]: disabled })}>
          {!disabled ? referee.status : tag}
        </Badge>
      }
    </div>
  </div>
)