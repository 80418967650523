import { CheckStatusRow } from 'components/CheckStatusRow/CheckStatusRow';
import { Button } from 'components/FormComponents';
import styles from './ChecksDetails.module.scss';
import { AddCheckModal } from 'components/AddCheckModal/AddCheckModal';
import { useState, useEffect } from 'react';
import { IconButton } from 'components/IconButton/IconButton';

export const ChecksDetails = ({
  token,
  companyChecks,
  company,
  forms,
  candidate,
  setCandidate            = () => {},
  setLoading              = () => {},
  getCandidateCheckResult = () => {},
  getSubCheckResult       = () => {},
  addCandidateChecks      = () => {},
  cancelCandidateCheck    = () => {},
  updateCandidateCheck    = () => {},
  reload                  = () => {}
}) => {

  const [checks, setChecks] = useState([]);
  const [showAddChecksModal, setShowAddChecksModal] = useState(false);

  useEffect(() => setChecks(candidate.candidate_checks), [candidate])

  const handleNewChecks = async(checksData) => {
    setShowAddChecksModal(false);
    setLoading(true);
    const { data: candidateData } = await addCandidateChecks({ token, applicationId: candidate.partner_application_id, checks: checksData.checks });
    setCandidate(candidateData);
    setLoading(false);
  }

  const handleCancelCheck = async (candidateCheckId) => {
    setLoading(true);
    const { data: updatedCheck } = await cancelCandidateCheck({ token, applicationId: candidate.partner_application_id, checkId: candidateCheckId });
    setChecks(checks.map(check => check.id === updatedCheck.id ? updatedCheck : check));
    setLoading(false);
  }

  const handleCandidateCheckUpdate = async (candidateCheckId, data) => {
    const { data: updatedCheck } = await updateCandidateCheck({ token, applicationId: candidate.partner_application_id, checkId: candidateCheckId, data });
    setChecks(curr => curr.map(check => check.id === updatedCheck.id ? updatedCheck : check));
  }

  const handleGetCandidateCheckResult = ({ check, candidate, isFile }) => getCandidateCheckResult({ token, check, candidate, isFile });
  const handleGetSubCheckResult = ({ subCheck, check, candidate, isFile }) => getSubCheckResult({ token, subCheck, check, candidate, isFile });

  return (
    <div className={styles.root}>
      <AddCheckModal
        companyChecks={companyChecks}
        forms={forms}
        visible={showAddChecksModal}
        onSubmit={handleNewChecks}
        onClose={() => setShowAddChecksModal(false)}
      />
      {checks.map(check => {
        const checkType = companyChecks.find(companyCheck => companyCheck.type === check.type)?.check_type

        return (
          <CheckStatusRow 
            key={check.id} 
            company={company}
            candidate={candidate}
            checkType={checkType}
            check={check}
            setLoading={setLoading}
            getCandidateCheckResult={handleGetCandidateCheckResult}
            getSubCheckResult={handleGetSubCheckResult}
            handleCancelCheck={handleCancelCheck}
            handleCheckUpdate={handleCandidateCheckUpdate}
          />
        )
      })}
      <div className='d-flex justify-content-between align-items-center u-margin-top--large'>
        <Button onClick={() => setShowAddChecksModal(true)} small className='u-margin-right--small'>+ Add Checks</Button>
        <IconButton active icon='arrows-rotate' prefix='fa' tip='Refresh details' onClick={reload}/>
      </div>
    </div>
  )
}